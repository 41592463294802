import React from 'react'

import Layout from '../layouts/home'
import SEO from '../components/seo'

export default function IndexPage() {

  return <Layout>
    <SEO
      title="Thank you for contacting us."
      keywords={[]}
    />
    <section class="slider d-flex align-items-center">
      <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-12">
        <div class="slider-title_box">
          <div class="row">
            <div class="col-md-12">
              <div class="slider-content_wrap">
              <h4>
                We have received your message.
              </h4>
              <p>We will get back to you as soon as possible. Thank you for reaching out to us.</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </section>
  </Layout>
}

